import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/mdx-page.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Visual Studio Code`}</h1>
    <h2>{`Generate installed extensions list`}</h2>
    <p>{`Copy and paste outcome to somewhere safe for backup.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`code --list-extensions | xargs -L 1 echo code --install-extension
`}</code></pre>
    <h2>{`Make sure terminal opens always right`}</h2>
    <p>{`Add to settings.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-bash"
      }}>{`"workbench.panel.defaultLocation": "right"
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      